const Data = [
  {
    id: 1,
    title: 'Snake',
    url: '/snake/',
    imgUrl: '/snake/snake.gif',
    description1:
      "Start the Game: Press the Space bar (desktop) or tap the 'Start Game' button (mobile) to begin.",
    description2:
      'Control the Snake: Use arrow keys (↑, ↓, ←, →) or swipe (mobile) to eat food, grow longer, and increase your score.',
    description3:
      'Avoid Collisions: Prevent the snake from colliding with its own body to keep playing, and enjoy the wrap-around movement through walls.',
  },
  {
    id: 2,
    title: 'Tic-Tac-Toe',
    url: '/tic-tac-toe/',
    imgUrl: '/tic-tac-toe/tic-tac-toe.gif',
    description1:
      "The game is played on a grid that's 3 squares by 3 squares. If you are X, your friend is O.",
    description2: 'Players take turns putting their marks in empty squares.',
    description3:
      'The first player to get 3 of their marks in a row (up, down, across, or diagonally) is the winner.',
  },
  {
    id: 3,
    title: 'Memory Card',
    url: '/memory-card/',
    imgUrl: '/memory-card/memory-card.gif',
    description1:
      'In the memory card game, player takes turns flipping over two cards to find matching pairs.',
    description2:
      'The player can only flip two cards per turn, trying to remember the location of previous flips.',
    description3:
      'The game continues until all pairs are found, and the timer stops after all cards are found.',
  },
  {
    id: 4,
    title: 'Whack-a-Mole',
    url: '/whack-a-mole/',
    imgUrl: '/whack-a-mole/whack-a-mole gif.gif',
    description1:
      'In the Whack-a-Mole game, player whacks moles with hammer for 30 seconds',
    description2: 'The player tries to get the high score under 30 second',
    description3: 'The goal is to set the highest score.',
  },
  {
    id: 5,
    title: 'BlackJack',
    url: '/black-jack/',
    imgUrl: '/black-jack/blackgif.gif',
    description1:
      'Objective: Have a hand value closer to 21 than the dealer without exceeding 21.',
    description2:
      'Player Actions: Hit (take a card) or Stand (keep current hand).',
    description3:
      'Dealer Rules: Must hit until reaching at least 17; closest to 21 wins without busting.',
  },
  {
    id: 6,
    title: 'RockPaperScissor',
    url: '/rock-paper-scissor/',
    imgUrl: '/rock-paper-scissor/rockpaperandscissor.gif',
    description1:
      'Each player simultaneously forms one of three shapes with their handRock, paper, and scissors are the three possible choices, where each option wins against one choice and loses to another; rock crushes scissors, scissors cuts paper, and paper covers rock.',
    description2:
      'Both players choose their move simultaneously and reveal their choices at the same time.',
    description3:
      'The player whose choice defeats the other wins the round; if both players choose the same option, the round is a tie.',
  },
  {
    id: 7,
    title: 'Hangman',
    url: '/hangman/',
    imgUrl: '/hangman/hangman.gif',
    description1:
      'Guess letters to reveal a hidden word, avoiding incorrect guesses to prevent the hangman from being completed.',
    description2:
      'Each incorrect guess adds a part to the hangman drawing; too many wrong guesses and you lose.',
    description3:
      'Players take turns guessing letters; correct guesses reveal the word, wrong guesses inch closer to losing.',
  },
  {
    id: 8,
    title: 'Word Scramble',
    url: '/word-scramble/',
    imgUrl: '/word-scramble/word-scramble gif1.gif',
    description1:
      'A jumbled collection of letters is given that represent a word.',
    description2:
      'A hint is also given that dscribes the word.',
    description3:
      'You have 30 seconds to rearrange the letters and guess the correct word.'
  },
  {
    id: 9,
    title: 'Catch The Ball',
    url: '/catch-the-ball/',
    imgUrl: '/catch-the-ball/catch.gif',
    description1:
      'Catch The Ball is a simple yet engaging game where the player controls a paddle to catch a bouncing ball, scoring points each time it hits the paddle.',
    description2:
      "As the game progresses, the ball's speed increases, making it more challenging.",
    description3:
      'The game tracks high scores, try to beat your high score. Simple, fun, and challenging!' 
 },
 {
  id: 10,
  title: 'Flappy Bird',
  url: '/flappy-bird/',
  imgUrl: '/flappy-bird/flappyBird.gif',
  description1: 'In Flappy Bird, the player taps the screen on phone or presses space or UpArrow key on PC to keep the bird in the air and navigate through gaps between pipes.',
	description2: 'The player must time their taps to control the height of the bird, avoiding both pipes and the ground.',
	description3: 'The game continues until the bird crashes into a pipe or falls, with the goal being to achieve the highest possible score.'
}
];

export default Data;
